.label {
    display: flex;
    cursor: pointer;
    user-select: none;
}

.inputWrapper {
    display: inline-block;
    position: relative;
    flex: 0 0 auto;
    width: var(--space-24);
    height: var(--space-24);
    margin-right: var(--space-8);
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-deprecated-ink-lighter);
    border-radius: 4px;
    background-color: var(--color-deprecated-white);
}

.checkmark::after {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    text-align: center;
}

.label .checkmark::after {
    content: '\2713';
    color: var(--color-deprecated-white);
}

.label input:checked ~ .checkmark {
    background-color: var(--color-green-700);
}

.label input:disabled ~ .checkmark,
.label input:checked:disabled ~ .checkmark {
    background-color: var(--color-deprecated-ink-extra-light);
}

.label:hover input:not(:checked, :disabled) ~ .checkmark {
    border-width: 2px;
}

.label:hover input:checked:not(:disabled) ~ .checkmark {
    background-color: var(--color-green-800);
}

.label input:checked ~ .checkmark::after {
    display: block;
}
