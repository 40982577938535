.button {
    --padding-with-icon: 1.8rem;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-12) 0;
    border: none;
    border-radius: var(--space-8);
    box-shadow: none;
    text-align: center; /* Force it for when the button is obfuscated so the element used is an <a> */
    cursor: pointer;
}

.primary,
.secondary,
.secondaryNew,
.tertiary {
    padding-right: var(--space-24);
    padding-left: var(--space-24);
}

.primary {
    background-color: var(--color-secondary-main);
    color: var(--color-secondary-contrast-neutral);
}

.primary:hover {
    background-color: var(--color-secondary-hovered);
}

.primary:focus {
    background-color: var(--color-secondary-pressed);
}

.primary.isDisabled {
    background-color: var(--color-secondary-disabled);
    color: var(--color-text-disabled);
    cursor: default;
}

.secondary {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px var(--color-primary-main);
    color: var(--color-primary-main);
}

.secondary:hover {
    background-color: var(--color-primary-hovered);
    color: var(--color-primary-contrast-neutral);
}

.secondary:focus {
    background-color: var(--color-primary-pressed);
    color: var(--color-primary-contrast-neutral);
}

.secondary.isDisabled {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px var(--color-border-disabled);
    color: var(--color-text-disabled);
    cursor: default;
}

.secondary.isReversed {
    box-shadow: inset 0 0 0 1px var(--color-primary-contrast-neutral);
    color: var(--color-primary-contrast-neutral);
}

.secondary.isReversed:hover {
    background-color: var(--color-neutral-0-10);
    color: var(--color-primary-contrast-neutral);
}

.secondary.isReversed:focus {
    background-color: var(--color-neutral-0-10);
    color: var(--color-primary-contrast-neutral);
}

.secondary.isReversed.isDisabled {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px currentColor;
    color: var(--color-text-disabled);
    cursor: default;
}

.secondaryNew {
    background-color: var(--color-green-700);
    color: var(--color-primary-contrast-neutral);
}

.secondaryNew:hover {
    background-color: var(--color-green-900);
}

.secondaryNew:focus {
    background-color: var(--color-primary-pressed);
}

.secondaryNew.isDisabled {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px var(--color-border-disabled);
    color: var(--color-text-disabled);
    cursor: default;
}

.tertiary {
    background-color: var(--color-beige-200);
    color: var(--color-text-main);
}

.tertiary:hover {
    background-color: var(--color-beige-300);
}

.tertiary:focus {
    background-color: var(--color-beige-400);
}

.tertiary.isDisabled {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px var(--color-border-disabled);
    color: var(--color-text-disabled);
    cursor: default;
}

.tertiary.isReversed {
    background-color: var(--color-primary-main);
    color: var(--color-primary-contrast-neutral);
}

.tertiary.isReversed:hover {
    background-color: var(--color-primary-pressed);
}

.tertiary.isReversed:focus {
    background-color: var(--color-primary-pressed);
}

.tertiary.isReversed.isDisabled {
    background-color: var(--color-neutral-800);
    box-shadow: none;
    color: var(--color-text-disabled);
    cursor: default;
}

.text {
    background-color: transparent;
    color: var(--color-primary-main);
    text-decoration: underline;
}

.text:hover {
    color: var(--color-primary-hovered);
    text-decoration: none;
}

.text:focus {
    color: var(--color-primary-pressed);
    text-decoration: none;
}

.text.isReversed {
    color: var(--color-primary-contrast-neutral);
}

.text.isReversed:hover,
.text.isReversed:focus {
    text-decoration: none;
}

.text.isDisabled,
.text.isReversed.isDisabled {
    color: var(--color-text-disabled);
    text-decoration: none;
    cursor: default;
}

.text:not(.isDisabled).alertInformation {
    color: var(--color-blue-700);
}

.text:not(.isDisabled).alertInformation:hover {
    color: var(--color-blue-800);
}

.text:not(.isDisabled).alertInformation:focus {
    color: var(--color-blue-800);
}

.text:not(.isDisabled).alertSuccess {
    color: var(--color-green-600);
}

.text:not(.isDisabled).alertSuccess:hover {
    color: var(--color-green-800);
}

.text:not(.isDisabled).alertSuccess:focus {
    color: var(--color-green-800);
}

.text:not(.isDisabled).alertWarning {
    color: var(--color-orange-900);
}

.text:not(.isDisabled).alertWarning:hover {
    color: var(--color-orange-800);
}

.text:not(.isDisabled).alertWarning:focus {
    color: var(--color-orange-800);
}

.text:not(.isDisabled).alertError {
    color: var(--color-red-600);
}

.text:not(.isDisabled).alertError:hover {
    color: var(--color-red-800);
}

.text:not(.isDisabled).alertError:focus {
    color: var(--color-red-800);
}

.icon {
    width: 2rem;
    height: 2rem;
}

.iconLeft:not(.text) {
    padding-left: var(--padding-with-icon);
}

.iconLeft .icon {
    margin-right: var(--space-8);
}

.iconRight:not(.text) {
    padding-right: var(--padding-with-icon);
}

.iconRight .icon {
    margin-left: var(--space-8);
}

.fullWidth {
    width: 100%;
}
