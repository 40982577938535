.footer {
    --flex-gap: var(--space-16);

    display: flex;
    flex-direction: row;
    padding: var(--space-16) var(--modal-content-horizontal-padding);
    gap: var(--flex-gap);
    border-top: 1px solid var(--color-border-main);
    background-color: var(--color-background-main);
}

.footer.hasOnlyLeftButton {
    justify-content: flex-start;
}

.footer.hasOnlyRightButton {
    justify-content: flex-end;
}

.footer.dark {
    border-top: none;
    background-color: var(--color-text-main);
    color: var(--color-background-main);
}

.buttons {
    display: flex;
    flex: 1 1 auto;
}

.buttons.hasBothSides {
    flex: 1 1 50%;
}

.buttons > * {
    width: 100%;
}

.primaryButton {
    justify-content: flex-end;
}

.secondaryButton {
    justify-content: flex-start;
}

@media (min-width: 768px) {
    .buttons {
        flex: 0 0 auto;
    }

    .buttons > * {
        width: auto;
    }
}
