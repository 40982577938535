.alert {
    display: flex;
    align-items: center;
    padding: var(--space-16);
    gap: var(--space-16);
    border: 1px solid transparent;
    border-radius: 4px;
    line-height: var(--line-height-body);
}

.alert.withButton {
    padding-right: var(--space-24);
}

.content {
    display: flex;
    flex: 1 0 0;
    align-items: flex-start;
    gap: var(--space-8);
}

.textWrapper {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-4);
}

.icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
}

.information {
    border-color: var(--color-info-main);
    background-color: var(--color-info-light);
    color: var(--color-info-contrast-neutral);
}

.informationIcon {
    color: var(--color-info-main);
}

.success {
    border-color: var(--color-success-main);
    background-color: var(--color-success-light);
    color: var(--color-success-contrast-neutral);
}

.successIcon {
    color: var(--color-success-main);
}

.warning {
    border-color: var(--color-warning-main);
    background-color: var(--color-warning-light);
    color: var(--color-warning-contrast-neutral);
}

.warningIcon {
    color: var(--color-warning-main);
}

.error {
    border-color: var(--color-error-main);
    background-color: var(--color-error-light);
    color: var(--color-error-contrast-neutral);
}

.errorIcon {
    color: var(--color-error-main);
}
